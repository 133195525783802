import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      component: () => import("@/pages/tnb-log")
    },
    {
      name: "login",
      path: "/login",
      component: () => import("@/pages/login")
    },
    {
      name: "user-management",
      path: "/user-management",
      component: () => import("@/pages/user-management")
    },
    {
      name: "team-management",
      path: "/team-management",
      component: () => import("@/pages/team-management")
    },
    {
      name: "inspection-status",
      path: "/inspection-status",
      component: () => import("@/pages/inspection-status")
    },
    {
      name: "email-logs",
      path: "/email-logs",
      component: () => import("@/pages/email-logs")
    },
    {
      name: "roles",
      path: "/user-management/roles",
      component: () => import("@/pages/roles")
    },
    {
      name: "departments",
      path: "/user-management/departments",
      component: () => import("@/pages/departments")
    },
    {
      name: "teams",
      path: "/user-management/teams",
      component: () => import("@/pages/teams")
    },
    {
      name: "tnb-defect-review",
      path: "/tnb-defect-review",
      component: () => import("@/pages/tnb-images")
    },
    {
      name: "defect-page",
      path: "/defect-page/:id/poles/:pid",
      component: () => import("@/pages/defect-page")
    },
    {
      name: "tnb-logs",
      path: "/logs",
      component: () => import("@/pages/tnb-log")
    },
    {
      name: "tnb-blocked-users",
      path: "/user-block-management",
      component: () => import("@/pages/tnb-blocked-user")
    },
    {
      name: "project-management",
      path: "/project-management",
      component: () => import("@/pages/project-management")
    },
    {
      name: "subsidiary-management",
      path: "/subsidiary-management",
      component: () => import("@/pages/subsidiary-management")
    },
    {
      name: "tnb-blocked-users-filter",
      path: "/tnb-blocked-users-filter",
      component: () => import("@/pages/tnb-blocked-user")
    }
  ]
});

export default router;
