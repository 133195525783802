import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // auth_data: {
    //   token: null,
    //   user: null
    // }
  },
  getters: {
    // isAuthenticated : state => {
    //   console.log(localStorage.getItem('user'))
    //   return (state.auth_data.token && state.auth_data.user) ? true : false;
    // },
  },
  mutations: {
    // setAuthData(state, {token, user}) {
    //   state.auth_data.token = token;
    //   state.auth_data.user = user;
    //   localStorage.clear();
    //   localStorage.setItem('token', token);
    //   localStorage.setItem('user', btoa(user));
    // },

    // clearAuthData(state) {
    //   state.auth_data.token = null;
    //   state.auth_data.user = null;
    //   localStorage.clear();
    // }
  },
  actions: {
    // SET_AUTH_DATA({commit}, {token, user}) {
    //   commit('setAuthData', {token, user})
    // },
    // ON_RELOAD_SET_AUTH_DATA({commit}, ) {
    //   commit('onReloadSetAuthData')
    // },
    // CLEAR_AUTH_DATA({commit}) {
    //   commit('clearAuthData')
    // }
  }
})