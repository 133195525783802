import AdminAPI from "@/services/admin_api";

export default {
  data() {
    return {};
  },
  created: function() {},
  methods: {
    // validation rules ------------------------------------------------------
    required(label) {
      return v => !!v || `${label} is required`;
    },
    email() {
      return v => /.+@.+\..+/.test(v) || !v || "E-mail must be valid";
    },
    passwordStrong(label) {
      return v => {
        var message = "";
        // if (!/[a-z]+/.test(v)) {
        //   message += "One lowercase letter required,";
        // }
        // if (!/[A-Z]+/.test(v) || ) {
        //   message += "8 character long, with combinations of alphanumeric, and special characters";
        // }
        // if (!/.{8,}/.test(v) || ) {
        //   message += "8 character long, with combinations of alphanumeric, and special characters";
        // }
        // if (!/[0-9]+/.test(v) || ) {
        //   message += "8 character long, with combinations of alphanumeric, and special characters";
        // }
        // if (!/[!@#\$%\^&\*]/.test(v) || ) {
        //     message += "8 character long, with combinations of alphanumeric, and special characters";
        // }
        if (!/[a-z]+/.test(v) || !/[A-Z]+/.test(v) || !/.{8,}/.test(v) || !/[0-9]+/.test(v) || !/[!@#\$%\^&\*]/.test(v) ) {
          message += "8 character long, with combinations of alphanumeric, and special characters";
        }
        return message == "" ? true : message.replace(/,\s*$/, "");
      };
    },

    // Alerts ---------------------------------------------------------------
    alert(type, title, msg) {
      this.$swal({
        type: type,
        title: title,
        text: msg,
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          popup: "animated fadeIn"
        }
      });
    },

    // get array of object data from api response ----------------------------
    getObjectArray(data, ind = "") {
      let selectBoxArr = data.map(arr => {
        let returnObj;
        if (ind == "") {
          returnObj = {
            id: arr.name,
            name: arr.name
          };
        } else {
          returnObj = {
            id: arr._id,
            name: arr.name
          };
        }
        return returnObj;
      });
      return selectBoxArr;
    },
    //get collections
    getCollection(collectionsArr, name) {
      const collection = collectionsArr.find(c => c.name == name);

      if (collection) return collection.items;

      return [];
    },

    //get metadata
    getMetadata(metadataArr, key) {
      const metadata = metadataArr.find(m => m.key == key);

      if (metadata) return metadata.value;

      return null;
    },
    //clear level filters
    emptyTextBoxes(ind, arr, val = "") {
      let flag = 0;
      for (var key in arr) {
        if (flag == 1) {
          if (val) arr[key] = null;
          else arr[key] = [];
        }
        if (key == ind) flag = 1;
      }
    },
    // api methods to get post put

    // fecth list
    async getData(url) {
      let res_list = await AdminAPI.get(url);
      const returnList = this.getObjectArray(res_list.data, "id");
      return returnList;
    },

    // add data
    async addData(url, data) {
      let res_data = await AdminAPI.post(url, data);
      return res_data;
    },

    // update data
    async updateData(url, data) {
      let res_data = await AdminAPI.put(url, data);
      return res_data;
    },

    // delete data
    async deleteData(url) {
      let res_data = await AdminAPI.get(url);
      return res_data;
    }
  }
};
