import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import vuetify from "./plugins/vuetify"; // material plugin
import VueSweetalert2 from "vue-sweetalert2"; // Alert pluign
import "sweetalert2/dist/sweetalert2.min.css"; // Alert CSS
import moment from "moment"; // Time Formate plugin
import DisableAutocomplete from "vue-disable-autocomplete";
import Viewer from "v-viewer"; // image viewer plugin
import "viewerjs/dist/viewer.css"; // image viewer css
import VueGoodTablePlugin from "vue-good-table";

// import the styles
import "vue-good-table/dist/vue-good-table.css";

Vue.config.productionTip = false;

Vue.use(VueSweetalert2);
Vue.use(DisableAutocomplete);
Vue.use(Viewer);
Vue.use(VueGoodTablePlugin);

// filters
// foramate date dd/mm/yyyy
Vue.filter("formate_ddmmyyyy", function(value) {
  if (!value) return "";
  return moment(String(value)).format("DD/MM/YYYY");
});

// formate float to fixed or round of
Vue.filter("round_of", function(value) {
  if (!value) return "";
  return parseFloat(value).toFixed(5);
});

// check authentication
const isAuthenticated = () => {
  if (localStorage.getItem("token") && localStorage.getItem("user") && localStorage.getItem("sessionActive")) {
    return true;
  } else {
    return false;
  }
};
// router gaurd
router.beforeEach((to, from, next) => {
  if (!isAuthenticated() && to.path !== "/login") next("/login");
  else next();
});

new Vue({
  store,
  router,
  vuetify,
  moment,
  render: h => h(App)
}).$mount("#app");
