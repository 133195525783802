import axios from 'axios'
import config from '../config'

export default class API {
  static get(module, base_domain='') {
    return new Promise( async(resolve, reject) => {
      try {  
        this._showLoader()     
        const opts = this._opts(module, 'get', '', base_domain)
        const response = await axios(opts)
        if (response) {
          this._stopLoader()         
        }
        
        resolve(response);
      }
      catch (err) {
        if (err.response && err.response.status == 401){
          this._stopLoader()  
          localStorage.removeItem("auth");
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          localStorage.removeItem("userData");
          localStorage.removeItem("sessionActive"); 
          window.location = '#/login'  
        } 
          
        reject(err)        
      }
    })    
  }
static post(module, data, base_domain='') {
    return new Promise( async(resolve, reject) => {
      try {
        // console.log('****', module, base_domain)     
        this._showLoader()   
        const opts = this._opts(module, 'post', data, base_domain)
        const response = await axios(opts)
        if (response) {
          this._stopLoader()
        }
        resolve(response);
      }
      catch (err) {
        if (err.response && err.response.status == 401){
          this._stopLoader()  
          localStorage.removeItem("auth");
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          localStorage.removeItem("userData");
          localStorage.removeItem("sessionActive"); 
          window.location = '#/login'  
        } 
          
        reject(err)        
      }
    })    
  }
static put(module, data, base_domain='') {
    return new Promise(async(resolve, reject) => {
      try {        
        this._showLoader()
        const opts = this._opts(module, 'put', data, base_domain)
        const response = await axios(opts)
        if (response) {
          this._stopLoader()
        }
        resolve(response);
      }
      catch (err) {
        // console.log(err)
        if (err.response && err.response.status == 401){
          this._stopLoader()  
          localStorage.removeItem("auth");
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          localStorage.removeItem("userData");
          localStorage.removeItem("sessionActive"); 
          window.location = '#/login'  
        } 

        reject(err)        
      }
    })    
  }

  static delete(module, base_domain='') {
    return new Promise(async (resolve, reject) => {
      try {      
        this._showLoader()  
        const opts = this._opts(module, 'delete', '',  base_domain)
        const response = await axios(opts)
        if (response) {
          this._stopLoader()
        }
        resolve(response);
      }
      catch (err) {
        if (err.response && err.response.status == 401){
          this._stopLoader()  
          localStorage.removeItem("auth");
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          localStorage.removeItem("userData");
          localStorage.removeItem("sessionActive"); 
          window.location = '#/login'  
        } 
          
        reject(err)        
      }
    })    
  }

  static postForm(module, data, base_domain='') {
    return new Promise(async(resolve, reject) => {
      try {
        this._showLoader()
        const opts = this._opts(module, 'post', data, base_domain)
        opts.headers['Content-Type'] = 'multipart/form-data';
        const response = await axios(opts)
        if (response) {
          this._stopLoader()
        }
        resolve(response);
      } catch (err) {
        if (err.response && err.response.status == 401){
          this._stopLoader()  
          localStorage.removeItem("auth");
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          localStorage.removeItem("userData");
          localStorage.removeItem("sessionActive"); 
          window.location = '#/login'  
        } 
        reject(err)
      }
    })
  }

  static _opts(module, method, data, base_domain) {

    const opts = {
      method: method,
      url: ''       
    }

    if(base_domain == '')
        opts.url = `${config.baseAdminAPIDomain}/${module}`
    else
        opts.url = `${config.baseAPIDomain}/${module}`

    
    // baseAdminAPIDomain
    if (localStorage.getItem('token'))
      opts.headers = { 'Authorization': `Bearer ${localStorage.getItem('token')}`, 'lala': '123' }

    if (data)
      opts.data = data    

    return opts
  }

  static _showLoader() {
    setTimeout(() => {
      if (document == null) document.getElementById("full-loader").style.display = "none";
      else document.getElementById("full-loader").style.display = "block"
    }, 10);
  }
  static _stopLoader() {
    setTimeout(() => {
      if (document == null) document.getElementById("full-loader").style.display = "none";
      else document.getElementById("full-loader").style.display = "none";
    }, 10);
  }
}