<template>
  <v-navigation-drawer permanent app clipped expand-on-hover>
    <template v-slot:prepend>
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <v-img src="@/assets/user.png"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="title">{{ user_name }}</v-list-item-title>
            <v-list-item-subtitle>{{ user_email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>

    <v-divider></v-divider>

    <v-list nav dense>
      <v-list-item
        v-if="
          user_role &&
          (user_role == 'Admin' ||
            user_role == 'Super Admin' ||
            user_role == 'AdminClient')
        "
        link
        :to="{ name: 'user-management' }"
      >
        <v-list-item-icon>
          <v-icon>mdi-account-group</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Users</v-list-item-title>
      </v-list-item>
      <!-- <v-list-item link :to="{name: 'inspection-status'}">
        <v-list-item-icon>
          <v-icon>mdi-file-document-box-search</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Inspection status</v-list-item-title>
      </v-list-item>-->
      <!-- <v-list-item link :to="{name: 'email-logs'}">
        <v-list-item-icon>
          <v-icon>mdi-email-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Email logs</v-list-item-title>
      </v-list-item>
      <v-list-item link :to="{name: 'tnb-defect-review'}">
        <v-list-item-icon>
          <v-icon>mdi-google-photos</v-icon>
        </v-list-item-icon>
        <v-list-item-title>TNB Defect-review</v-list-item-title>
      </v-list-item>-->
      <v-list-item link :to="{ name: 'team-management' }">
        <v-list-item-icon>
          <v-icon>mdi-microsoft-teams</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Team Management</v-list-item-title>
      </v-list-item>
      <v-list-item link :to="{ name: 'tnb-logs' }">
        <v-list-item-icon>
          <v-icon>mdi-note</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Logs</v-list-item-title>
      </v-list-item>
      <v-list-item link :to="{ name: 'tnb-blocked-users' }">
        <v-list-item-icon>
          <v-icon>mdi-yoga</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Users management</v-list-item-title>
      </v-list-item>
      <v-list-item link :to="{ name: 'project-management' }">
        <v-list-item-icon>
          <v-icon>mdi-animation</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Project Management</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="
          user_role && user_role == 'Super Admin'" 
            link :to="{ name: 'subsidiary-management' }">
        <v-list-item-icon>
          <v-icon>mdi-account-multiple-plus</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Subsidiary Management</v-list-item-title>
      </v-list-item>
      <v-list-item link :to="{ name: 'tnb-blocked-users-filter' }">
        <v-list-item-icon>
          <v-icon>mdi-bell-off</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Blocked Users</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "sidenav",
  data() {
    return {
      user_name: "",
      user_email: "",
      user_role: "",
    };
  },
  methods: {
    getUserDetails() {
      let temp_user = localStorage.getItem("user");
      if(temp_user){
      temp_user = atob(temp_user);
      temp_user = JSON.parse(temp_user);
      this.user_email = temp_user.email;
      this.user_name = temp_user.fullname;
      this.user_role = temp_user.role;
      }
     
    },
  },
  mounted() {
    this.getUserDetails();
  },
};
</script>