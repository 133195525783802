<template>
  <v-app>
    <!-- header  (if cond modified after auth done) -->
    <!-- <full-loader></full-loader> -->
    <v-app-bar
      app
      clipped-left
      color="appcolor"
      dark
      v-if="$route.path !== '/login'"
    >
      <v-toolbar-title class="headline text-uppercase">
        <span>GRID</span>
        <span class="font-weight-light">- ADMIN PANEL</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- <v-btn
        v-if="$route.name !== 'defect-page'"
        class="ma-2"
        outlined
        tile
        @click.prevent="logout"
      >
        LOGOUT
        <v-icon right>mdi-logout</v-icon>
      </v-btn> -->
      <!-- <template> -->
      <div class="text-center" style="display: contents;">
        <div>Hi, {{ loggedUser && loggedUser.email ? loggedUser.email : "User" }}</div>
        <v-menu  offset-y :close-on-content-click="closeOnContentClick">
          <template v-slot:activator="{ on, attrs }">
            <v-avatar dark v-bind="attrs" v-on="on">
              <v-icon dark>
                mdi-account-circle
              </v-icon>
            </v-avatar>
          </template>

          <v-list>
            <v-list-item>
              <v-list-item-title class="pointer" @click="dialog = true"
                ><v-icon small> mdi-key-change </v-icon>&nbsp;&nbsp;Change
                Password</v-list-item-title
              >
            </v-list-item>
            <v-list-item>
              <v-list-item-title @click.prevent="logout" class="pointer"
                ><v-icon small> mdi-logout-variant </v-icon
                >&nbsp;&nbsp;Logout</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <!-- </template> -->
    </v-app-bar>

    <!-- side navigation (if cond modified after auth done) -->
    <sidenav
      v-if="$route.name !== 'defect-page' && $route.path !== '/login'"
    ></sidenav>

    <!-- content -->
    <v-content>
      <router-view></router-view>
    </v-content>

    <template>
      <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="400px">
          <v-card>
            <v-card-title>
              <span class="headline"
                >Change Password<span class="email"
                  >( {{ loggedUser && loggedUser.email ? loggedUser.email : "User" }} )</span
                ></span
              >
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form ref="form" v-model="valid">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="Old Password*"
                        type="password"
                        :rules="rules.oldPassword"
                        v-model="oldPassword"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="New Password*"
                        v-model="password"
                        :rules="rules.password"
                        type="password"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="Confirm Password*"
                        v-model="confirm_password"
                        :rules="
                          rules.confirm_password.concat(
                            passwordConfirmationRule
                          )
                        "
                        type="password"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
              <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false; $refs.form.reset()">
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :disabled="!valid"
                @click.prevent="submit"
                @click="dialog = false"
              >
                Change
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </v-app>
</template>

<script>
import sidenav from "@/components/sidenav";
import AdminAPI from "@/services/admin_api";
import Mixins from "@/mixin";
// import login from "@/pages/login";
import FullLoader from "@/components/full-loader.vue";

export default {
  name: "App",
  mixins: [Mixins],
  components: {
    sidenav,
    "full-loader": FullLoader,
    // login
  },
  data() {
    return {
      closeOnContentClick: true,
      closeOnClick: true,
      dialog: false,
      loggedUser: null,
      password: null,
      oldPassword: null,
      confirm_password: null,
      valid: true,
      // validation rules
      rules: {
        password: [this.required("Password"), this.passwordStrong()],
        confirm_password: [
          this.required("Confirm Password"),
          this.passwordStrong(),
        ],
        oldPassword: [this.required("Password")]
      },
       loading: false
    };
  },

  watch: {
    async $route(newRoute, oldRoute) {
      this.loggedUser = JSON.parse(localStorage.getItem("userData"));
    }
  },

  computed: {
    passwordConfirmationRule() {
      return () =>
        this.password === this.confirm_password || "Password must match";
    },
  },
  methods: {
    logout() {
      this.$router.push({ name: "login" });
      localStorage.clear();
    },
    async submit() {
      if (!this.$refs.form.validate()) return;
      let body = {
        new_password: this.password,
        old_password: this.oldPassword,
      };
      let response = await AdminAPI.post(`changePassword`, body);
      if (response.data.status) {
        this.alert("success", "", response.data.success);
        this.$refs.form.reset()
      } else {
        this.alert("error", "", response.data.error);
      }
    },
  },
  mounted() {
    // this.loggedUser = JSON.parse(localStorage.getItem("userData"));
  },

};
</script>

<style>
/* global css */
.email {
  margin-left: 10px;
  font-style: italic;
  font-weight: 300;
}
.pointer {
  cursor: pointer;
}
.breadcrumb-header {
  display: inline-flex;
  align-items: center;
}
.v-breadcrumbs {
  padding: 0 !important;
}
.warning-msg {
  color: #fb8c00;
  font-size: 14px;
  font-weight: bold;
}
.captalize {
  text-transform: capitalize;
}
.view-table {
  margin: auto;
  justify-content: center;
  text-align: left;
  width: 60%;
}
.v-application .headline {
  font-size: 1.1rem !important;
  font-weight: 500;
}
</style>
