import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        // primary: '#1976D2',
        primary: '#808368',
        appcolor: '#808368',
        accent: '#e91e63',
        secondary: '#30B1DC',
        success: '#4CAF50',
        info: '#2196F3',
        warning: '#FB8C00',
        error: '#EC2B2B',
        Cautious: '#F0BE47',
        Critical: '#D91700',
        Low: '#5B98DE',
        Normal: '#21A921'
      }
    }
  }
});
